import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { User, UserRole } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { usePaginatedUsersByRoleQuery } from './usePaginatedUsersByRoleQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useUsersByRoleBuildTable } from './useUsersByRoleBuildTable'
import { EmptyRoleUsers } from './EmptyRoleUsers'

interface Props {
  userRole?: UserRole
  organizationId?: string
}

export const RolUsers = ({ userRole, organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')

  // Request data
  const { query } = usePaginatedUsersByRoleQuery()
  const {
    data: paginatedUsers,
    isFetching,
    refetch: refetchUsers,
  } = usePaginatedData<User>(query, 'getPaginatedUsersByRole', [], {
    organizationId: organizationId,
    userRoleId: userRole?._id,
  })

  const { columns } = useUsersByRoleBuildTable({ t, i18n })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '76vh' }}>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <PaginatedTable
          tableId="roles-users"
          locale={i18n.language}
          paginatedData={paginatedUsers}
          onRefresh={refetchUsers}
          columns={columns}
          filters={{
            filterFields: [],
            smartSearch: true,
            advancedSearch: false,
          }}
          emptyInitialLoadContent={<EmptyRoleUsers {...{ t }} />}
          isFetching={isFetching}
        />
      </div>
    </div>
  )
}
