import { InstalledAppView } from '../details/hooks/useGetInstalledAppById'
import { useTranslation } from 'react-i18next'
import usePaginatedData from '../../../../hooks/usePaginatedData'
import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { usePaginatedInstalledAppVersionListQuery } from './hooks/usePaginatedInstalledAppVersionListQuery'
import { InstalledAppVersion } from './InstalledAppVersion'
import { useBuildInstalledAppVersionsTable } from './hooks/useBuildInstalledAppVersionsTable'
import { useInstalledAppVersionsFilter } from './hooks/useInstalledAppVersionsFilter'
import { useTableExport } from '../../../../hooks/utils/useTableExport'
import { useInstalledAppVersionsActions } from './hooks/useInstalledAppVersionsActions'
import { useInstalledAppVersionsBuildExport } from './hooks/useInstalledAppVersionsBuildExport'
import { useInstalledAppVersions } from './hooks/useInstalledAppVersions'

interface Props {
  installedApp: InstalledAppView
}

export const InstalledAppVersionsList = ({ installedApp }: Props) => {
  const { t, i18n } = useTranslation('analyzer')

  const { filterFields } = useInstalledAppVersionsFilter({ t })
  const { query, queryName } = usePaginatedInstalledAppVersionListQuery()
  const {
    data: paginatedInstalledAppVersions,
    isFetching,
    refetch: istalledAppsVersionsRefetch,
  } = usePaginatedData<InstalledAppVersion>(query, queryName, filterFields, {
    installedAppId: installedApp.id,
  })

  const { refetch: refetchExportInstalledAppVersions } = useInstalledAppVersions(installedApp.id)
  const { installedAppVersionsTableExportColumns } = useInstalledAppVersionsBuildExport({ t })
  const { onExportClicked } = useTableExport({
    query: refetchExportInstalledAppVersions,
    columns: installedAppVersionsTableExportColumns,
    fileName: 'Installed app versions',
  })
  const { commandBarActions } = useInstalledAppVersionsActions({ t, onExportClicked })

  const { columns } = useBuildInstalledAppVersionsTable({ t })

  return (
    <PaginatedTable
      tableId="installed-app-versions"
      paginatedData={paginatedInstalledAppVersions}
      onRefresh={istalledAppsVersionsRefetch}
      columns={columns}
      filters={{
        smartSearch: true,
        advancedSearch: true,
        filterFields: filterFields,
      }}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus message={t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.EMPTY_MESSAGE')} />
        </div>
      }
      isFetching={isFetching}
      commands={commandBarActions}
      locale={i18n.language}
    />
  )
}
