import { LoadingSpinner, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { ReportingGroup, UserRole } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { usePaginatedReportingGroupRolesQuery } from './usePaginatedReportingGroupRolesQuery'
import { useReportingGroupRolesBuildTable } from './useReportingGroupRolesBuildTable'
import { useNavigate } from 'react-router-dom'
import { useRolesFilters } from 'app/pages/organization-details/roles/useRolesFilters'
import { EmptyReportingGroupRoles } from './EmptyReportingGroupRoles'

interface Props {
  reportingGroup: ReportingGroup
  organizationId: string
}

export const ReportingGroupRoles = ({ reportingGroup, organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const navigate = useNavigate()

  const { query } = usePaginatedReportingGroupRolesQuery()
  const { filterFields } = useRolesFilters({ t })

  const {
    data: paginatedRoles,
    isFetching,
    isLoading,
    refetch: refetchRoles,
  } = usePaginatedData<UserRole>(query, 'getPaginatedReportingGroupRoles', filterFields, {
    organizationId: organizationId,
    reportingGroupId: reportingGroup._id,
  })

  const onViewDetails = (role: UserRole) => {
    navigate(`/organization/${organizationId}/roles/${role._id}`)
  }

  // Actions and columns
  const { columns } = useReportingGroupRolesBuildTable({ t, i18n, onViewDetails })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '76vh' }}>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            <PaginatedTable
              tableId="reporting-group-roles"
              locale={i18n.language}
              paginatedData={paginatedRoles}
              onRefresh={refetchRoles}
              columns={columns}
              filters={{
                filterFields,
                smartSearch: true,
                advancedSearch: true,
              }}
              isFetching={isFetching}
              emptyInitialLoadContent={<EmptyReportingGroupRoles {...{ t }} />}
            />
          </>
        )}
      </div>
    </div>
  )
}
